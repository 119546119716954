/* global axios */
import ApiClient from './ApiClient';

class GroupsAPI extends ApiClient {
  constructor() {
    super('groups', { accountScoped: true });
  }

  get() {
    return axios.get(this.url);
  }

  create(data) {
    return axios.post(this.url, data);
  }

  update(id, data) {
    return axios.patch(`${this.url}/${id}`, data);
  }

  delete(id) {
    return axios.delete(`${this.url}/${id}`);
  }

  // State management
  updateState(id, state) {
    return axios.patch(`${this.url}/${id}/group_states/update`, { id, state });
  }

  // Item management
  moveItem(groupId, data) {
    return axios.post(`${this.url}/${groupId}/group_items/move`, data);
  }

  removeItem(groupId, itemId) {
    return axios.delete(`${this.url}/${groupId}/group_items/remove`, {
      data: { item_id: itemId }
    });
  }

  clearItems(id) {
    return axios.delete(`${this.url}/${id}/group_items/clear`);
  }
}

export default new GroupsAPI();
