<!-- app/javascript/dashboard/routes/dashboard/settings/inbox/channels/ApiInboxWhatsappTemplate.vue -->
<template>
  <div class="whatsapp-config-form">
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.businessAccountId.$error }">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.CREDENTIALS_SECTION.WABA_ID.LABEL') }}
        <input v-model.trim="businessAccountId" type="text"
          :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.CREDENTIALS_SECTION.WABA_ID.PLACEHOLDER')"
          @blur="$v.businessAccountId.$touch" @input="onConfigChange" />
        <span v-if="$v.businessAccountId.$error" class="message">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.CREDENTIALS_SECTION.WABA_ID.ERROR') }}
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.apiKey.$error }">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.CREDENTIALS_SECTION.ACCESS_TOKEN.LABEL') }}
        <input v-model.trim="apiKey" type="password"
          :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.CREDENTIALS_SECTION.ACCESS_TOKEN.PLACEHOLDER')"
          @blur="$v.apiKey.$touch" @input="onConfigChange" />
        <span v-if="$v.apiKey.$error" class="message">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.CREDENTIALS_SECTION.ACCESS_TOKEN.ERROR') }}
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%] mt-4">
      <!-- <woot-button
        :disabled="!canFetchTemplates"
        variant="primary"
        @click="onFetchTemplates"
      >
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.TEMPLATES_SECTION.FETCH_BUTTON') }}
      </woot-button> -->
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label>
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_REOPEN') }}
        <select v-model="reopenConversation" @change="onConfigChange">
          <option :value="true">
            {{ $t('INBOX_MGMT.EDIT.ENABLE_WHATSAPP.ENABLED') }}
          </option>
          <option :value="false">
            {{ $t('INBOX_MGMT.EDIT.ENABLE_WHATSAPP.DISABLED') }}
          </option>
        </select>
      </label>
    </div>

    <div v-if="reopenConversation" class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.reopenTimeWindow.$error }">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.REOPEN_DURATION.LABEL') }}
        <input v-model.number="reopenTimeWindow" type="number" min="1" max="48" @blur="$v.reopenTimeWindow.$touch"
          @input="onConfigChange" />
        <span v-if="$v.reopenTimeWindow.$error" class="message">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.REOPEN_DURATION.ERROR') }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { required, between } from 'vuelidate/lib/validators';

export default {
  name: 'ApiInboxWhatsappTemplate',
  props: {
    initialValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      businessAccountId: this.initialValues.businessAccountId || '',
      apiKey: this.initialValues.apiKey || '',
      reopenConversation: this.initialValues.reopenConversation || false,
      reopenTimeWindow: this.initialValues.reopenTimeWindow || 24,
    };
  },
  computed: {
    canFetchTemplates() {
      return this.businessAccountId && this.apiKey;
    },
  },
  validations: {
    businessAccountId: { required },
    apiKey: { required },
    reopenTimeWindow: {
      required: function (val) { return this.reopenConversation; },
      between: between(1, 48),
    },
  },
  methods: {
    onConfigChange() {
      const config = {
        business_account_id: this.businessAccountId,
        api_key: this.apiKey,
        reopen_conversation: this.reopenConversation,
        reopen_time_window: this.reopenTimeWindow,
        message_templates_last_updated: new Date().toISOString(),
      };
      this.$emit('update', config);

      // Auto-fetch templates if credentials are valid
      if (this.businessAccountId && this.apiKey) {
        this.$emit('fetch-templates', {
          business_account_id: this.businessAccountId,
          api_key: this.apiKey,
          status: ['APPROVED'],
        });
      }
    },
    onFetchTemplates() {
      if (!this.canFetchTemplates) return;
      
      this.$emit('fetch-templates', {
        business_account_id: this.businessAccountId,
        api_key: this.apiKey,
        status: ['APPROVED'],
      });
    }
  },
  watch: {
    businessAccountId() {
      this.onConfigChange();
    },
    apiKey() {
      this.onConfigChange();
    },
    reopenConversation() {
      this.onConfigChange();
    },
    reopenTimeWindow() {
      this.onConfigChange();
    }
  }
};
</script>