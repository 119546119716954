<template>
  <div 
    class="group-container transition-all pl-4"
    :class="groupClasses"
    :draggable="isAdmin"
    :data-group-id="group.id"
    @dragover.prevent="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent="handleDrop"
    @contextmenu.prevent="$emit('show-context-menu', $event)"
  >
    <!-- Group Header -->
    <div class="flex items-center justify-between p-2 cursor-pointer max-w-full w-full" @click="toggleCollapsed">
      <div class="flex items-center overflow-hidden flex-1 min-w-0 mr-2">
        <fluent-icon
          :icon="folderIcon"
          size="16"
          class="mr-1.5 rtl:mr-0 rtl:ml-1.5 flex-shrink-0"
        />
        <span
          class="text-sm font-medium text-slate-700 dark:text-slate-100 truncate"
          v-tooltip="group.name.length > 12 ? group.name : ''"
        >
          {{ group.name }}
        </span>
      </div>
      <fluent-icon
        :icon="chevronIcon"
        size="10"
        class="flex-shrink-0"
      />
    </div>


    <!-- Group Content -->
    <transition name="slide">
      <div v-show="!isCollapsed" class="">
        <slot></slot>
      </div>
    </transition>      
  </div>
</template>

<script>
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  name: 'Group',
  mixins: [adminMixin],
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      isDragOver: false,
    };
  },
  computed: {
    hasItems() {
      return this.group.items && this.group.items.length > 0;
    },
    isCollapsed: {
      get() {
        const state = this.group.state || {};
        return state.collapsed || false;
      },
      set(value) {
        this.$store.dispatch('groups/updateState', {
          groupId: this.group.id,
          state: { collapsed: value }
        });
      }
    },
    groupClasses() {
      return {
        'opacity-50': this.isDragging && this.isAdmin,
        'border-2 border-dashed border-woot-500 rounded-lg bg-woot-500/25 border-animation': this.isDragOver,
        'opacity-60 text-slate-700 dark:text-slate-100': !this.hasItems
      };
    },
    folderIcon() {
      return this.isCollapsed ? 'folder' : 'folder-open';
    },
    chevronIcon() {
      return this.isCollapsed ? 'chevron-right' : 'chevron-down';
    }
  },
  mounted() {
    document.addEventListener('dragend', this.resetDragStyles);
  },

  beforeDestroy() {
    document.removeEventListener('dragend', this.resetDragStyles);
  },
  methods: {
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
    resetDragStyles() {
      this.isDragging = false;
      this.isDragOver = false;
    },
    handleDragOver() {
      if (this.isAdmin) {
        if (event.target.closest('.group-container') === this.$el) {
          this.isDragOver = true;
          if (this.isCollapsed) {
            this.isCollapsed = false;
          }
          event.stopPropagation();
        }
      }
    },
    handleDragLeave() {
      if (!event.relatedTarget?.closest(`[data-group-id="${this.group.id}"]`)) {
        this.isDragOver = false;
      }
    },
    handleDrop(e) {
      this.resetDragStyles();
      const data = JSON.parse(e.dataTransfer.getData('text/plain'));
      
      if (data.type === 'item') {
        this.$store.dispatch('groups/moveItemToGroup', {
          groupId: this.group.id,
          itemId: data.id,
          sourceGroupId: data.sourceGroupId
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.border-animation {
  @apply transition-all duration-200;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 16px 2px, 16px 2px, 2px 16px, 2px 16px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0%;
  padding: 4px;
  animation: border-dance 0.8s infinite linear;
}


.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>