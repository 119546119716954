<template>
	<div
		class="item-selector relative flex items-center p-4 border rounded-lg cursor-pointer transition-all"
		:class="selectorClasses"
		v-tooltip.top="tooltipText"
		@click="$emit('select', item)"
	>
		<span class="mr-3">
			<fluent-icon
				:class="iconClasses"
				:icon="getItemIcon"
				size="16"
			/>
		</span>
		
		<span :class="textClasses" class="truncate text-base">
			{{ item.name }}
		</span>
		
		<div 
			v-if="hasExistingGroup"
			class="absolute top-2 right-2"
		>
			<fluent-icon
				size="14"
				icon="warning"
			/>
		</div>

		<span 
			v-if="selected"
			class="absolute bottom-2 right-2"
		>
			<fluent-icon 
				icon="checkmark" 
				size="16" 
				color="primary" 
			/>
		</span>
	</div>
</template>

<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		hasExistingGroup: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		selectorClasses() {
			return {
				'border-woot-500 bg-woot-500/25': this.selected,
				'border-slate-200 hover:border-woot-500 hover:bg-woot-500/25': !this.selected,
			};
		},
		iconClasses() {
			return {
				'text-woot-500 font-semibold': this.selected,
				'text-slate-700 dark:text-slate-200': !this.selected,
			};
		},
		textClasses() {
			return {
				'text-woot-500': this.selected,
				'text-slate-700 dark:text-slate-200': !this.selected,
			};
		},
		getItemIcon() {
			return this.item.channel_type ? 
				getInboxClassByType(this.item.channel_type, this.item.phone_number) : 
				'people-team';
		},
		tooltipText() {
			return this.hasExistingGroup ? this.$t('GROUP_MODAL.MOVE_ITEM_MESSAGE') : '';
		},
	},
};
</script>
<style lang="scss" scoped>
.item-selector {
  @apply transition-transform duration-200 ease-in-out;
  
  &:hover {
    transform: translateY(-2px);
  }
}
</style>