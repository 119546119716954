<template>
  <woot-modal :show="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('GROUP_MODAL.EDIT.TITLE')" />
    <form class="flex flex-col p-8" @submit.prevent="handleSubmit">
      <!-- Name Input -->
      <div class="flex flex-col mb-4">
        <label class="block text-sm font-medium mb-2">
          {{ $t('GROUP_MODAL.EDIT.NAME.LABEL') }}
        </label>
        <input
          v-model="formData.name"
          type="text"
          class="w-full rounded-md border-slate-300 dark:border-slate-700"
          :placeholder="$t('GROUP_MODAL.EDIT.NAME.PLACEHOLDER')"
          :class="{ 'border-red-500': errors.name }"
          @input="validateName"
        />
        <span v-if="errors.name" class="text-red-500 text-xs mt-1">
          {{ errors.name }}
        </span>
      </div>

      <!-- Items Selection -->
      <div class="flex flex-col mb-4">
        <label class="block text-sm font-medium mb-2">
          {{ $t('GROUP_MODAL.EDIT.ITEMS.LABEL') }}
        </label>
        <div class="grid grid-cols-2 gap-4 max-h-40 overflow-y-auto p-2">
          <item-selector
            v-for="item in availableItems"
            :key="item.id"
            :item="item"
            :selected="isItemSelected(item.id)"
            :has-existing-group="hasExistingGroup(item)"
            @select="toggleItemSelection"
          />
        </div>
        <span v-if="errors.items" class="text-red-500 text-xs mt-1">
          {{ errors.items }}
        </span>
      </div>

      <!-- Action Buttons -->
      <div class="flex justify-end mt-6">
        <woot-button
          type="button"
          class="mr-2"
          variant="clear"
          size="small"
          @click="onClose"
        >
          {{ $t('GROUP_MODAL.EDIT.CANCEL') }}
        </woot-button>
        <woot-button
          :is-loading="isSubmitting"
          variant="primary"
          size="small"
          type="submit"
          :disabled="!isFormValid"
        >
          {{ $t('GROUP_MODAL.EDIT.SAVE') }}
        </woot-button>
      </div>
    </form>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ItemSelector from './ItemSelector.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ItemSelector,
  },
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      formData: {
        name: '',
        selectedItems: [],
      },
      errors: {
        name: '',
        items: '',
      },
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters({
      teams: 'teams/getTeams',
      inboxes: 'inboxes/getInboxes',
      groups: 'groups/getGroups',
    }),
    availableItems() {
      if (!this.group) return [];

      const items = this.group.group_type === 'team' ? this.teams : this.inboxes;
      return items.map(item => ({
        ...item,
        currentGroupId: this.getItemGroupId(item.id),
      }));
    },
    isFormValid() {
      return (
        this.formData.name.trim() &&
        this.formData.selectedItems.length > 0 &&
        !this.errors.name &&
        !this.errors.items
      );
    },
  },
  watch: {
    group: {
      immediate: true,
      handler(group) {
        if (group && group.items) {
          this.formData = {
            name: group.name,
            selectedItems: group.items.map(item => item.id),
          };
        }
      },
    },
    show(newValue) {
      if (!newValue) {
        this.resetForm();
      }
    },
  },
  methods: {
    validateName() {
      this.errors.name = '';
      if (!this.formData.name.trim()) {
        this.errors.name = this.$t('GROUP_MODAL.EDIT.ERRORS.NAME_REQUIRED');
      }
    },
    getItemGroupId(itemId) {
      return this.groups.find(
        g => 
          g.group_type === this.group.group_type && 
          g.id !== this.group.id && 
          g.items.some(i => i.id === itemId)
      )?.id;
    },
    isItemSelected(itemId) {
      return this.formData.selectedItems.includes(itemId);
    },
    hasExistingGroup(item) {
      return item.currentGroupId && item.currentGroupId !== this.group.id;
    },
    toggleItemSelection(item) {
      const index = this.formData.selectedItems.indexOf(item.id);
      if (index === -1) {
        this.formData.selectedItems.push(item.id);
        this.handleExistingGroupItem(item);
      } else {
        this.formData.selectedItems.splice(index, 1);
      }
      this.validateItems();
    },
    async handleExistingGroupItem(item) {
      if (item.currentGroupId && item.currentGroupId !== this.group.id) {
        await this.$store.dispatch('groups/updateItems', {
          groupId: item.currentGroupId,
          itemIds: this.getUpdatedItemIds(item.currentGroupId, item.id),
        });
      }
    },
    getUpdatedItemIds(groupId, itemIdToRemove) {
      const group = this.groups.find(g => g.id === groupId);
      return group.items
        .filter(item => item.id !== itemIdToRemove)
        .map(item => item.id);
    },
    validateItems() {
      this.errors.items = '';
      if (!this.formData.selectedItems.length) {
        this.errors.items = this.$t('GROUP_MODAL.EDIT.ERRORS.ITEMS_REQUIRED');
      }
    },
    async handleSubmit() {
      this.validateName();
      this.validateItems();

      if (!this.isFormValid) return;

      this.isSubmitting = true;
      try {
        await this.$store.dispatch('groups/update', {
          id: this.group.id,
          name: this.formData.name,
          item_ids: this.formData.selectedItems,
        });
        this.showAlert(this.$t('GROUP_MODAL.EDIT.SUCCESS'));
        this.onClose();
      } catch (error) {
        this.errors.submit = error.message;
      } finally {
        this.isSubmitting = false;
      }
    },
    resetForm() {
      this.formData = {
        name: this.group.name,
        selectedItems: this.group.items.map(item => item.id),
      };
      this.errors = {
        name: '',
        items: '',
      };
      this.isSubmitting = false;
    },
    onClose() {
      this.resetForm();
      this.$emit('close');
    },
  },
};
</script>
