<template>
  <woot-modal :show="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('GROUP_MODAL.DELETE.TITLE')" />
    <div class="w-full">
      <div class="p-8">
        <p>{{ deleteMessage }}</p>
        <div class="flex justify-end mt-6">
          <woot-button 
            type="button" 
            class="mr-2" 
            variant="clear" 
            size="small" 
            @click="onClose"
          >
            {{ $t('GROUP_MODAL.DELETE.CANCEL') }}
          </woot-button>
          <woot-button 
            variant="alert" 
            color-scheme="danger" 
            size="small" 
            @click="onConfirm"
          >
            {{ $t('GROUP_MODAL.DELETE.YES') }}
          </woot-button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    deleteMessage() {
			if (!this.group?.name) return '';
			
			if (!this.group.items?.length) {
				return this.$t('GROUP_MODAL.DELETE.CONFIRM_EMPTY', {
					name: this.group.name
				});
			}
			
			return this.$t(
				this.group.items.length === 1 
					? 'GROUP_MODAL.DELETE.CONFIRM_SINGLE' 
					: 'GROUP_MODAL.DELETE.CONFIRM', 
				{
					name: this.group.name,
					count: this.group.items.length
				}
			);
		}
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      this.$emit('confirm');
      this.onClose();
    },
  },
};
</script>