<template>
  <li
    class="group-dropdown mt-1"
    :class="dropdownClasses"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragLeave"
    @drop.prevent="handleDrop"
  >
    <!-- Header -->
    <div class="flex items-center p-2 m-0 text-sm font-medium leading-4 cursor-pointer rounded-lg text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-800">
      <div class="flex items-center justify-between w-full" @click="toggleDropdown">
        <div class="flex items-center">
          <fluent-icon
            :icon="dropdownIcon"
            size="14"
            type="outline"
            class="min-w-[1rem] mr-1.5 rtl:mr-0 rtl:ml-1.5 text-slate-700 dark:text-slate-100"
          />
          <span class="text-sm font-medium text-slate-700 dark:text-slate-100">
            {{ title }}
          </span>
        </div>
        <fluent-icon
          :icon="isOpen ? 'chevron-down' : 'chevron-right'"
          size="10"
          class="mr-1.5 rtl:mr-0 rtl:ml-1.5 text-slate-700 dark:text-slate-100"
        />
      </div>
    </div>

    <!-- Content -->
    <transition name="slide">
      <div
        v-show="isOpen"
        class=""
        @dragover.prevent
        @drop.prevent="handleContentDrop"
      >
        <slot></slot>
      </div>
    </transition>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  name: 'GroupDropdown',
  mixins: [adminMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value => ['team', 'inbox'].includes(value)
    }
  },
  data() {
    return {
      isOpen: false,
      isDragOver: false
    };
  },
  computed: {
    dropdownIcon() {
      return this.type === 'team' ? 'people-team' : 'mail-inbox';
    },
    dropdownClasses() {
      return {
        'border-2 border-dashed border-woot-500 rounded-lg': this.isDragOver && this.isAdmin
      };
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleDragOver(event) {
      if (this.isAdmin) {
        const dropdownArea = this.$el;
        const isOverGroup = event.target.closest('.group-container');
        
        if (dropdownArea.contains(event.target) && !isOverGroup) {
          this.isDragOver = true;
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },

    handleDragLeave(event) {
      const dropdownArea = this.$el;
      const relatedTarget = event.relatedTarget;
      const isOverGroup = relatedTarget?.closest('.group-container');
      
      if (!dropdownArea.contains(relatedTarget) || isOverGroup) {
        this.isDragOver = false;
      }
    },
    handleDrop(e) {
      if (!this.isAdmin) return;

      const data = JSON.parse(e.dataTransfer.getData('text/plain'));
      this.isDragOver = false;
      
      if (data.type === 'item' && data.sourceGroupId) {
        this.$store.dispatch('groups/removeItemFromGroup', {
          groupId: data.sourceGroupId,
          itemId: data.id
        });
      }
    },
    handleContentDrop(e) {
      const data = JSON.parse(e.dataTransfer.getData('text/plain'));
      if (data.type === 'item') {
        this.$emit('item-dropped', data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.group-dropdown {
  &:hover {
    .dropdown-icon {
      opacity: 1;
    }
  }
}
</style>