import GroupsAPI from '../../../api/groups';
import * as types from './types';

export const actions = {
  // Core CRUD
  async get({ commit }) {
    commit(types.SET_GROUPS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await GroupsAPI.get();
      commit(types.SET_GROUPS, data.payload);
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isFetching: false });
    }
  },

  async create({ commit, dispatch }, groupData) {
    commit(types.SET_GROUPS_UI_FLAG, { isCreating: true });
    try {
      const { data } = await GroupsAPI.create(groupData);
      commit(types.SET_GROUP_ITEM, data);
      await dispatch('get');
      return data;
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isCreating: false });
    }
  },  

  async update({ commit, dispatch }, { id, ...updateData }) {
    commit(types.SET_GROUPS_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await GroupsAPI.update(id, updateData);
      commit(types.SET_GROUP, data);
      await dispatch('get');
      return data;
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isUpdating: false });
    }
  },

  async delete({ commit }, groupId) {
    commit(types.SET_GROUPS_UI_FLAG, { isDeleting: true });
    try {
      await GroupsAPI.delete(groupId);
      commit(types.DELETE_GROUP, groupId);
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isDeleting: false });
    }
  },

  // State Management
  async updateState({ commit }, { groupId, state }) {
    commit(types.SET_GROUPS_UI_FLAG, { isUpdatingState: true });
    try {
      await GroupsAPI.updateState(groupId, state);
      commit(types.UPDATE_GROUP_STATE, { groupId, state });
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isUpdatingState: false });
    }
  },

  updateItems: async ({ commit, dispatch }, { groupId, itemIds }) => {
    commit(types.SET_GROUPS_UI_FLAG, { isUpdating: true });
    try {
      const response = await GroupsAPI.update(groupId, { item_ids: itemIds });
      commit(types.UPDATE_GROUP_ITEMS, { id: groupId, items: response.data.items });
      return response.data;
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isUpdating: false });
    }
  },

  // Item Management
  async moveItemToGroup({ commit, dispatch }, { groupId, itemId, sourceGroupId }) {
    commit(types.SET_GROUPS_UI_FLAG, { isMovingItem: true });
    try {
      
      if (sourceGroupId) {
        await GroupsAPI.removeItem(sourceGroupId, itemId);
      }

      const { data } = await GroupsAPI.moveItem(groupId, { item_id: itemId });
      commit(types.SET_GROUP, data);
      await dispatch('get');
      return data;
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isMovingItem: false });
    }
  },

  async removeItemFromGroup({ commit }, { groupId, itemId }) {
    commit(types.SET_GROUPS_UI_FLAG, { isUpdating: true });
    try {
      await GroupsAPI.removeItem(groupId, itemId);
      const { data } = await GroupsAPI.get();
      commit(types.SET_GROUPS, data.payload);
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isUpdating: false });
    }
  },

  async clearItems({ commit }, groupId) {
    commit(types.SET_GROUPS_UI_FLAG, { isUpdating: true });
    try {
      const { data } = await GroupsAPI.clearItems(groupId);
      commit(types.SET_GROUP, data);
      return data;
    } finally {
      commit(types.SET_GROUPS_UI_FLAG, { isUpdating: false });
    }
  },
};
