export const getters = {
  getGroups: $state => {
    return Object.values($state.records).sort((a, b) => 
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
  },

  getGroupsByType: $state => type => {
    return Object.values($state.records)
      .filter(group => group.group_type === type)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  },

  getTeamGroups: state => getGroupsByType(state)('team_group'),
  
  getInboxGroups: state => getGroupsByType(state)('inbox_group'),

  getUIFlags: $state => $state.uiFlags,
};
