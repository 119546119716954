// Core CRUD
export const SET_GROUPS_UI_FLAG = 'SET_GROUPS_UI_FLAG';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUP_ITEM = 'SET_GROUP_ITEM';
export const EDIT_GROUP = 'EDIT_GROUP';
export const UPDATE_GROUP_POSITION = 'UPDATE_GROUP_POSITION';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const SET_GROUP = 'SET_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';

// State Management
export const UPDATE_GROUP_STATE = 'UPDATE_GROUP_STATE';

// Item Management
export const UPDATE_GROUP_ITEMS = 'UPDATE_GROUP_ITEMS';
export const MOVE_GROUP_ITEM = 'MOVE_GROUP_ITEM';
export const REMOVE_GROUP_ITEM = 'REMOVE_GROUP_ITEM';
export const CLEAR_GROUP_ITEMS = 'CLEAR_GROUP_ITEMS';
