<template>
  <woot-modal :show="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('GROUP_MODAL.CREATE.TITLE')" />
    <form class="flex flex-col p-8" @submit.prevent="handleSubmit">
      <!-- Name Input -->
      <div class="flex flex-col mb-4">
        <label class="block text-sm font-medium mb-2">
          {{ $t('GROUP_MODAL.CREATE.NAME.LABEL') }}
        </label>
        <input
          ref="nameInput"
          v-model="formData.name"
          type="text"
          class="w-full rounded-md border-slate-300 dark:border-slate-700"
          :placeholder="$t('GROUP_MODAL.CREATE.NAME.PLACEHOLDER')"
          :class="{ 'border-red-500': errors.name }"
          @input="validateName"
        />
        <span v-if="errors.name" class="text-red-500 text-xs mt-1">
          {{ errors.name }}
        </span>
      </div>

      <!-- Type Selection -->
      <div class="flex flex-col mb-4">
        <label class="block text-sm font-medium mb-2">
          {{ $t('GROUP_MODAL.CREATE.TYPE.LABEL') }}
        </label>
        <select
          v-model="formData.type"
          class="w-full rounded-md border-slate-300 dark:border-slate-700"
          @change="handleTypeChange"
        >
          <option value="team">{{ $t('GROUP_MODAL.CREATE.OPTIONS.TEAMS') }}</option>
          <option value="inbox">{{ $t('GROUP_MODAL.CREATE.OPTIONS.INBOXES') }}</option>
        </select>
      </div>

      <!-- Items Selection -->
      <div class="flex flex-col mb-4">
        <label class="block text-sm font-medium mb-2">
          {{ $t('GROUP_MODAL.CREATE.ITEMS.LABEL') }}
        </label>
        <div class="grid grid-cols-2 gap-4 max-h-40 overflow-y-auto p-2">
          <item-selector
            v-for="item in availableItems"
            :key="item.id"
            :item="item"
            :selected="isItemSelected(item.id)"
            :has-existing-group="!!item.currentGroupId"
            @select="toggleItemSelection"
          />
        </div>
        <span v-if="errors.items" class="text-red-500 text-xs mt-1">
          {{ errors.items }}
        </span>
      </div>

      <!-- Action Buttons -->
      <div class="flex justify-end mt-6">
        <woot-button
          type="button"
          class="mr-2"
          variant="clear"
          size="small"
          @click="onClose"
        >
          {{ $t('GROUP_MODAL.CREATE.CANCEL') }}
        </woot-button>
        <woot-button
          :is-loading="isSubmitting"
          variant="primary"
          size="small"
          type="submit"
          :disabled="!isFormValid"
        >
          {{ $t('GROUP_MODAL.CREATE.CREATE') }}
        </woot-button>
      </div>
    </form>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ItemSelector from './ItemSelector.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ItemSelector,
  },
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        type: '',
        selectedItems: [],
      },
      errors: {
        name: '',
        items: '',
      },
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters({
      teams: 'teams/getTeams',
      inboxes: 'inboxes/getInboxes',
      groups: 'groups/getGroups',
    }),
    availableItems() {
      const items = this.formData.type === 'team' ? this.teams : this.inboxes;
      return items.map(item => ({
        ...item,
        currentGroupId: this.getItemGroupId(item.id),
      }));
    },
    isFormValid() {
      return (
        this.formData.name.trim() &&
        this.formData.type &&
        this.formData.selectedItems.length > 0 &&
        !this.errors.name &&
        !this.errors.items
      );
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(value) {
        this.formData.type = value;
      },
    },
    show(newValue) {
      if (!newValue) {
        this.resetForm();
      } else {
        this.$nextTick(() => {
          this.$refs.nameInput.focus();
        });
      }
    },
  },
  methods: {
    validateName() {
      this.errors.name = '';
      if (!this.formData.name.trim()) {
        this.errors.name = this.$t('GROUP_MODAL.CREATE.ERRORS.NAME_REQUIRED');
      }
    },
    getItemGroupId(itemId) {
      return this.groups.find(
        g => g.group_type === this.formData.type && g.items.some(i => i.id === itemId)
      )?.id;
    },
    isItemSelected(itemId) {
      return this.formData.selectedItems.includes(itemId);
    },
    toggleItemSelection(item) {
      const index = this.formData.selectedItems.indexOf(item.id);
      if (index === -1) {
        this.formData.selectedItems.push(item.id);
      } else {
        this.formData.selectedItems.splice(index, 1);
      }
      this.validateItems();
    },
    validateItems() {
      this.errors.items = '';
      if (!this.formData.selectedItems.length) {
        this.errors.items = this.$t('GROUP_MODAL.CREATE.ERRORS.ITEMS_REQUIRED');
      }
    },
    handleTypeChange() {
      this.formData.selectedItems = [];
      this.errors.items = '';
    },
    async handleSubmit() {
      this.validateName();
      this.validateItems();

      if (!this.isFormValid) return;

      this.isSubmitting = true;
      try {
        await this.$store.dispatch('groups/create', {
          name: this.formData.name,
          group_type: this.formData.type,
          item_ids: this.formData.selectedItems,
        });
        this.showAlert(this.$t('GROUP_MODAL.CREATE.SUCCESS'));
        this.onClose();
      } catch (error) {
        // Handle error based on your error handling strategy
        this.errors.submit = error.message;
      } finally {
        this.isSubmitting = false;
      }
    },
    resetForm() {
      this.formData = {
        name: '',
        type: this.type,
        selectedItems: [],
      };
      this.errors = {
        name: '',
        items: '',
      };
      this.isSubmitting = false;
    },
    onClose() {
      this.resetForm();
      this.$emit('close');
    },
  },
  mounted() {
    this.$refs.nameInput?.focus();
  },
};
</script>
<style lang="scss" scoped>
.grid {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.slate.300') transparent;

  &::-webkit-scrollbar {
    @apply w-2;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-slate-300 rounded-full transition-colors duration-200;
  }

  .dark & {
    &::-webkit-scrollbar-thumb {
      @apply bg-slate-600;
    }
  }
}
</style>