<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.API_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.API_CHANNEL.DESC')"
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label
          :class="{ error: $v.channelName.$error && $v.channelName.$dirty }"
        >
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
            @input="$v.channelName.$touch"
          />
          <span
            v-if="$v.channelName.$error && $v.channelName.$dirty"
            class="message"
            >{{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR') }}</span
          >
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.webhookUrl.$error && $v.webhookUrl.$dirty }">
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.LABEL') }}
          <input
            v-model.trim="webhookUrl"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.PLACEHOLDER')
            "
            @blur="$v.webhookUrl.$touch"
            @input="$v.webhookUrl.$touch"
          />
          <span
            v-if="$v.webhookUrl.$error && $v.webhookUrl.$dirty"
            class="message"
          >
            {{ $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.ERROR_INVALID') }}
          </span>
          <p v-else class="help-text">
            {{ $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.SUBTITLE') }}
          </p>
        </label>
      </div>

      <!-- <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label>
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_AGENT_BOT') }}
          <select v-model="agentBotEnabled" @change="onAgentBotToggle">
            <option :value="true">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_WHATSAPP.ENABLED') }}
            </option>
            <option :value="false">
              {{ $t('INBOX_MGMT.EDIT.ENABLE_WHATSAPP.DISABLED') }}
            </option>
          </select>
        </label>
      </div> -->

      <!-- <api-inbox-whatsapp-template
        v-if="agentBotEnabled"
        :initial-values="{
          phoneNumberId: phone_number_id,
          businessAccountId: business_account_id,
          apiKey: api_key,
        }"
        @update="updateWhatsAppConfig"
        @fetch-templates="fetchWhatsAppTemplates"
      /> -->

      <!-- <template-selector
        v-if="agentBotEnabled && messageTemplates.length"
        :templates="messageTemplates"
        :default-template-id="defaultTemplateId"
        :is-fetching="isFetchingTemplates"
        @template-selected="onTemplateSelect"
      /> -->

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :disabled="!isFormValid"
          :button-text="$t('INBOX_MGMT.ADD.API_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import {
  required,
  isPhoneE164OrEmpty,
  isNumber,
} from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';
import ApiInboxWhatsappTemplate from './ApiInboxWhatsappTemplate.vue';

const shouldBeWebhookUrl = (value = '') => {
  if (!value) return true; // Allow empty value
  try {
    const url = new URL(value);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      webhookUrl: '',
      // agentBotEnabled: false,
      // phone_number_id: '',
      // business_account_id: '',
      // api_key: '',
      // messageTemplates: [],
      // defaultTemplateId: '',
      // isFetchingTemplates: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    isFormValid() {
      if (!this.$v || !this.$v.$dirty) return false;
      return (
        this.channelName &&
        this.webhookUrl &&
        !this.$v.channelName.$error &&
        !this.$v.webhookUrl.$error
      );
    },
  },
  validations: {
    channelName: { required },
    webhookUrl: { required, shouldBeWebhookUrl },
    // phoneNumber: { required, isPhoneE164OrEmpty },
    // apiKey: { required },
    // phoneNumberId: { required, isNumber },
    // businessAccountId: { required, isNumber },
  },
  methods: {
    onAgentBotToggle(event) {
      this.agentBotEnabled = event.target.value === 'true';
      if (!this.agentBotEnabled) {
        this.phone_number_id = '';
        this.business_account_id = '';
        this.api_key = '';
      }
    },
    updateWhatsAppConfig(config) {
      this.phone_number_id = config.phone_number_id;
      this.business_account_id = config.business_account_id;
      this.api_key = config.api_key;
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            webhook_url: this.webhookUrl,
            agent_bot_enabled: this.agentBotEnabled,
            whatsapp_config: {
              api_key: this.apiKey,
              phone_number_id: this.phoneNumberId,
              business_account_id: this.businessAccountId,
            },
          },
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
    async fetchWhatsAppTemplates(credentials) {
      if (!credentials?.business_account_id || !credentials?.api_key) return;

      this.isFetchingTemplates = true;
      try {
        const response = await this.$store.dispatch(
          'inboxes/fetchWhatsAppTemplates',
          {
            accountId: this.accountId,
            credentials,
          }
        );
        this.messageTemplates = response.data || [];
        if (this.messageTemplates.length) {
          this.showAlert(
            this.$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.TEMPLATES_FETCHED')
          );
        } else {
          this.showAlert(
            this.$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP.NO_TEMPLATES')
          );
        }
      } catch (error) {
        this.showAlert(error.message);
      } finally {
        this.isFetchingTemplates = false;
      }
    },

    onTemplateSelect(templateId) {
      this.defaultTemplateId = templateId;
    },
  },
};
</script>
