<template>
	<div 
		class="menu-item"
		:class="[
			variantClass,
			{ 'has-submenu': hasSubmenu },
			{ 'disabled': disabled }
		]"
		@click="!disabled && $emit('click')"
		@mouseenter="$emit('mouseenter')"
		@mouseleave="$emit('mouseleave')"
	>
		<div class="menu-item__content">
			<fluent-icon 
				v-if="icon"
				:icon="icon" 
				size="16" 
				class="mr-2"
			/>
			<span>{{ label }}</span>
		</div>
		<fluent-icon 
			v-if="hasSubmenu"
			icon="chevron-right" 
			size="12"
		/>
		<slot />
	</div>
</template>

<script>
export default {
	name: 'MenuItem',
	props: {
		icon: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			required: true,
		},
		hasSubmenu: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		variant: {
			type: String,
			default: 'default',
			validator: value => ['default', 'danger'].includes(value),
		},
	},
	computed: {
		variantClass() {
			return {
				'text-red-500': this.variant === 'danger',
				'text-slate-700 dark:text-slate-100': this.variant === 'default',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.menu-item {
	@apply flex items-center justify-between px-4 py-2 hover:bg-slate-50 dark:hover:bg-slate-700 cursor-pointer;

	&.has-submenu {
		@apply relative;
	}

	&.disabled {
		@apply opacity-50 cursor-not-allowed;
	}

	&__content {
		@apply flex items-center;
	}
}
</style>
