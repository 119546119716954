import Vue from 'vue';
import * as types from './types';

export const mutations = {
  // UI Flags
  [types.SET_GROUPS_UI_FLAG]($state, data) {
    $state.uiFlags = { ...$state.uiFlags, ...data };
  },

  // Core CRUD
  [types.CLEAR_GROUPS]($state) {
    Vue.set($state, 'records', {});
  },

  [types.SET_GROUPS]($state, data) {
    data.forEach(group => {
      Vue.set($state.records, group.id, {
        ...($state.records[group.id] || {}),
        ...group,
        items: group.items || [],
        state: group.state || { collapsed: false }
      });
    });
  },

  [types.SET_GROUP]($state, data) {
    Vue.set($state.records, data.id, {
      ...($state.records[data.id] || {}),
      ...data,
      items: data.items || [],
      state: data.state || { collapsed: false }
    });
  },

  [types.DELETE_GROUP]($state, groupId) {
    Vue.delete($state.records, groupId);
  },

  // State Management
  [types.UPDATE_GROUP_STATE]($state, { groupId, state }) {
    const group = $state.records[groupId];
    if (group) {
      Vue.set(group, 'state', { ...group.state, ...state });
    }
  },

  // Item Management
  [types.UPDATE_GROUP_ITEMS]($state, { id, items }) {
    const group = $state.records[id];
    if (group) {
      Vue.set(group, 'items', items);
    }
  },
  
  [types.SET_GROUP_ITEM]: ($state, data) => {
    Vue.set($state.records, data.id, {
      ...($state.records[data.id] || {}),
      ...data,
      items: data.items || [],
      state: data.state || { collapsed: false }
    });
  }
};
