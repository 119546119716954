<template>
	<div>
		<!-- Teams Section -->
		<group-dropdown 
			v-if="teams.length"
			key="teams-dropdown"
			type="team"
			:title="$t('SIDEBAR.TEAMS')"
			@item-dropped="handleItemDrop"
			@create-group="createGroup('team_group')"
		>
			<div 
				class="groups-container"
				@dragover.prevent
			>
				<group
					v-for="group in teamGroups"
					:key="`group-${group.id}`"
					:group="group"
					@show-context-menu="showContextMenu($event, { itemType: '', itemId: group.id })"
				>
					<group-item
						v-for="team in group.items"
						:key="`team-${team.id}`"
						:item="team"
						item-type="team"
						:group-id="group.id"
					/>
				</group>
				
				<div class="unassigned-items">
					<group-item
						v-for="team in ungroupedTeams"
						:key="`team-${team.id}`"
						:item="team"
						item-type="team"
					/>
				</div>
			</div>

			<div class="pl-1">
				<woot-button
					v-if="isAdmin" 
					size="tiny"
					variant="clear"
					color-scheme="primary"
					icon="add"
					@click="openCreateGroupModal('team')"
				>
					{{ $t('SIDEBAR.NEW_FOLDER') }}
				</woot-button>
			</div>
		</group-dropdown>

		<!-- Inboxes Section -->
		<group-dropdown
			v-if="inboxes.length"
			key="inboxes-dropdown"
			type="inbox"
			:title="$t('SIDEBAR.INBOXES')"
			@create-group="createGroup('inbox_group')"
		>
			<group
				v-for="group in inboxGroups"
				:key="`group-${group.id}`"
				:group="group"
				@show-context-menu="showContextMenu($event, { itemType: '', itemId: group.id })"
			>
				<group-item
					v-for="inbox in group.items"
					:key="`inbox-${inbox.id}`"
					:item="inbox"
					item-type="inbox"
					:group-id="group.id"
					@show-context-menu="showContextMenu"
				/>
			</group>
			
			<div class="unassigned-items">
				<group-item
					v-for="inbox in ungroupedInboxes"
					:key="`inbox-${inbox.id}`"
					:item="inbox"
					item-type="inbox"
				/>
			</div>

			<div class="pl-1">
				<woot-button
					v-if="isAdmin" 
					size="tiny"
					variant="clear"
					color-scheme="primary"
					icon="add"
					@click="openCreateGroupModal('inbox')"
				>
					{{ $t('SIDEBAR.NEW_FOLDER') }}
				</woot-button>
			</div>
		</group-dropdown>

		<!-- Modals -->
		<group-context-menu
			v-if="isAdmin"
			:is-visible="contextMenu.isVisible"
			:position="contextMenu.position"
			:item-type="contextMenu.itemType"
			:item-id="contextMenu.itemId"
			:group="selectedGroup"
			@assign="handleAssign"
			@rename="handleRename"
			@empty="handleEmpty"
			@delete="handleDelete"
			@close="closeContextMenu"
		/>

		<create-group-modal
			:show="showCreateGroupModal"
			:type="selectedGroupType"
			@close="showCreateGroupModal = false"
		/>

		<delete-confirmation-modal
      :show="showDeleteModal"
			:group="selectedGroup"
      @close="showDeleteModal = false"
      @confirm="confirmDelete"
    />

		<edit-group-modal
			:show="showEditModal"
			:group="selectedGroup"
			@close="showEditModal = false"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import GroupDropdown from './GroupDropdown.vue';
import Group from './Group.vue';
import GroupItem from './GroupItem.vue';
import GroupContextMenu from './GroupContextMenu.vue';
import CreateGroupModal from './CreateGroupModal';
import DeleteConfirmationModal from './DeleteConfirmationModal.vue';
import EditGroupModal from './EditGroupModal';

export default {
	components: {
		GroupDropdown,
		Group,
		GroupItem,
		GroupContextMenu,
		CreateGroupModal,
    DeleteConfirmationModal,
		EditGroupModal,
	},
	mixins: [adminMixin],
	props: {
		teams: {
			type: Array,
			default: () => [],
		},
		inboxes: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			contextMenu: {
				isVisible: false,
				position: { x: 0, y: 0 },
				itemType: '',
				itemId: null,
			},
			showCreateGroupModal: false,
			showEditModal: false,
			selectedGroup: null,
			selectedGroupType: '',
			showDeleteModal: false,
			groupToDelete: null,
		};
	},
	computed: {
		...mapGetters({
			groups: 'groups/getGroups',
		}),
		teamGroups() {
			return this.groups
				.filter(group => group.group_type === 'team')
				.map(group => ({
					...group,
					items: group.items.filter(item => this.teams.some(t => t.id === item.id))
				}));
		},
		inboxGroups() {
			return this.groups
				.filter(group => group.group_type === 'inbox')
				.map(group => ({
					...group,
					items: group.items.filter(item => this.inboxes.some(i => i.id === item.id))
				}));
		},
		ungroupedTeams() {
			const groupedTeamIds = this.teamGroups
				.flatMap(group => group.items)
				.map(item => item.id);
			return this.teams.filter(team => !groupedTeamIds.includes(team.id));
		},
		ungroupedInboxes() {
			const groupedInboxIds = this.inboxGroups
				.flatMap(group => group.items)
				.map(item => item.id);
			return this.inboxes.filter(inbox => !groupedInboxIds.includes(inbox.id));
		},
		deleteConfirmationMessage() {
			if (!this.selectedGroup) return '';
			
			return this.$t('GROUP_MODAL.DELETE.CONFIRM', {
				name: this.selectedGroup.name,
				count: this.selectedGroup.items.length
			});
		}
	},
	methods: {
		createGroup(groupType) {
			this.$store.dispatch('groups/create', { group_type: groupType });
		},
		openCreateGroupModal(type) {
			this.selectedGroupType = type;
			this.showCreateGroupModal = true;
		},
		showContextMenu(event, { itemType = '', itemId = null } = {}) {
			this.contextMenu = {
				isVisible: true,
				position: { x: event.clientX, y: event.clientY },
				itemType,
				itemId,
			};
		},
		closeContextMenu() {
			this.contextMenu.isVisible = false;
		},
		handleRename() {
			const group = this.groups.find(g => g.id === this.contextMenu.itemId);
			this.selectedGroup = group;
			this.showEditModal = true;
			this.closeContextMenu();
		},
		handleEmpty() {
			this.$store.dispatch('groups/clearItems', this.contextMenu.itemId);
			this.closeContextMenu();
		},
		handleDelete() {
			const group = this.groups.find(g => g.id === this.contextMenu.itemId);
			this.selectedGroup = group;
			this.showDeleteModal = true;
			this.closeContextMenu();
		},
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.selectedGroup = null;
    },
    async confirmDelete() {
      await this.$store.dispatch('groups/delete', this.selectedGroup.id);
      this.closeDeleteModal();
    },
		handleAssign(data) {
			this.$store.dispatch('groups/update', {
				id: data.groupId,
				item_ids: [this.item.id]
			});
		},
		handleItemDrop(data) {
			if (data.sourceGroupId) {
				this.$store.dispatch('groups/moveItemToGroup', {
					itemId: data.id,
					sourceGroupId: data.sourceGroupId,
					targetGroupId: data.targetGroupId
				});
			}
		},
	},
	mounted() {
		this.$store.dispatch('groups/get');
	}
};
</script>
