<template>
  <div 
    v-if="isVisible"
    ref="menu"
    class="context-menu bg-white dark:bg-slate-800 border border-slate-100 dark:border-slate-700 rounded-lg shadow-lg"
    :style="menuPosition"
  >
    <div class="context-menu__items">
      <!-- Item specific options -->
      <template v-if="itemType">
        <menu-item
          icon="folder"
          :label="$t('GROUP_CONTEXT_MENU.ASSIGN')"
          :has-submenu="true"
          :disabled="!availableGroups.length"
          @mouseenter="showAssignMenu = availableGroups.length > 0"
          @mouseleave="showAssignMenu = false"
        >
          <div 
            v-if="showAssignMenu"
            class="context-menu__submenu"
          >
            <menu-item
              v-for="group in availableGroups"
              :key="group.id"
              :label="group.name"
              @click="assignToGroup(group.id)"
            />
          </div>
        </menu-item>
      </template>

      <!-- Group specific options -->
      <template v-else>
        <menu-item
          icon="edit"
          :label="$t('GROUP_CONTEXT_MENU.EDIT')"
          @click="$emit('rename')"
        />
        <menu-item
          icon="dismiss"
          :label="$t('GROUP_CONTEXT_MENU.EMPTY')"
          @click="$emit('empty')"
        />
        <menu-item
          icon="delete"
          :label="$t('GROUP_CONTEXT_MENU.DELETE')"
          variant="danger"
          @click="$emit('delete')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue';

export default {
  name: 'GroupContextMenu',
  components: {
    MenuItem,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
    itemType: {
      type: String,
      default: '',
    },
    itemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showAssignMenu: false,
    };
  },
  computed: {
    availableGroups() {
      const groupType = this.itemType === 'team' ? 'team' : 'inbox';
      return this.$store.getters['groups/getGroups'].filter(
        group => group.group_type === groupType
      );
    },
    menuPosition() {
      if (!this.position) return { top: '0px', left: '0px' };

      const viewport = {
        width: window.innerWidth,
        height: window.innerHeight
      };

      const menuWidth = 160; // min-width from CSS
      const menuHeight = 150; // approximate height of menu

      let left = this.position.x;
      let top = this.position.y;

      // Adjust horizontal position if menu would overflow viewport
      if (left + menuWidth > viewport.width) {
        left = viewport.width - menuWidth - 10;
      }

      // Adjust vertical position if menu would overflow viewport
      if (top + menuHeight > viewport.height) {
        top = viewport.height - menuHeight - 10;
      }

      return {
        top: `${top}px`,
        left: `${left}px`
      };
    }
  },
  methods: {
    assignToGroup(groupId) {
      this.$emit('assign', { groupId, itemId: this.itemId });
      this.$emit('close');
    },
    handleClickOutside(event) {
      if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
        this.$emit('close');
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.context-menu {
  position: fixed;
  z-index: 999;
  min-width: 160px;

  &__submenu {
    position: absolute;
    left: 100%;
    top: 0;
    min-width: 160px;
    @apply bg-white dark:bg-slate-800 border border-slate-100 dark:border-slate-700 rounded-lg shadow-lg;
  }
}
</style>