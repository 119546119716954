<template>
  <div
    class="group-item ml-4"
    :class="{ 'is-dragging': isDragging && isAdmin }"
    :draggable="isAdmin"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
    @contextmenu.prevent="handleContextMenu"
  >
    <router-link
      v-slot="{ href, isActive, navigate }"
      :to="itemRoute"
      custom
    >
      <li
        class="font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800"
        :class="{ 'bg-woot-25 dark:bg-slate-800': isActive }"
        @click="navigate"
      >
        <a
          :href="href"
          class="inline-flex text-left max-w-full w-full items-center"
        >
          <span class="inline-flex items-center justify-center w-4 rounded-sm p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5">
            <fluent-icon
              class="text-xxs"
              :class="iconClasses(isActive)"
              :icon="computedIcon"
              size="12"
            />
          </span>
          <div class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between">
            <span
              :title="item.name"
              :class="textClasses(isActive)"
            >
              {{ item.name }}
            </span>
          </div>
        </a>
      </li>
    </router-link>
  </div>
</template>

<script>
import { frontendURL } from 'dashboard/helper/URLHelper';
import adminMixin from 'dashboard/mixins/isAdmin';
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  name: 'GroupItem',
  mixins: [adminMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    itemType: {
      type: String,
      required: true,
      validator: value => ['team', 'inbox'].includes(value)
    },
    groupId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      isDragging: false,
    };
  },
  computed: {
    computedIcon() {
      if (this.itemType === 'team') {
        return 'people-team';
      }
      return getInboxClassByType(this.item.channel_type, this.item.phone_number);
    },
    itemRoute() {
      const accountId = this.$store.getters.getCurrentAccountId;
      return this.itemType === 'team' 
        ? frontendURL(`accounts/${accountId}/team/${this.item.id}`)
        : frontendURL(`accounts/${accountId}/inbox/${this.item.id}`);
    }
  },
  methods: {
    iconClasses(isActive) {
      return {
        'text-woot-500 dark:text-woot-500': isActive,
        'text-slate-700 dark:text-slate-200': !isActive
      };
    },
    textClasses(isActive) {
      return {
        'text-woot-500 dark:text-woot-500': isActive,
        'text-slate-700 dark:text-slate-100': !isActive,
        'text-ellipsis overflow-hidden whitespace-nowrap max-w-full': true
      };
    },
    handleDragStart(e) {
      if (!this.isAdmin) return;
      
      this.isDragging = true;
      e.dataTransfer.setData('text/plain', JSON.stringify({
        type: 'item',
        id: this.item.id,
        itemType: this.itemType,
        sourceGroupId: this.groupId
      }));
    },
    handleDragEnd() {
      this.isDragging = false;
    },
    handleContextMenu(event) {
      this.$emit('show-context-menu', event, { 
        itemType: this.itemType, 
        itemId: this.item.id 
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.group-item {
  &.is-dragging {
    opacity: 0.5;
  }
}
</style>
